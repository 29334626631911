import styled, { css } from "styled-components";

import { IS_SMALL } from "~/utils/media";
import { ANIMATION_DURATION } from "./search.constants";

export const SearchForm = styled.form`
  position: relative;
  margin: 0.6rem 0;

  ${IS_SMALL} {
    // just until I can style it for mobile
    pointer-events: none;
    visibility: hidden;

    margin: 0.4rem 0;
    margin-left: auto;
  }
`;

export const SearchAnimation = styled.div`
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 4px;
  will-change: transform, background-color;
`;

export const SearchInput = styled.input.attrs(() => ({
  placeholder: "Search The Good Snail",
}))`
  align-self: center;
  width: 240px;
  background-color: transparent;
  outline: none;
  border: none;
`;

export const SearchInputContainer = styled.div`
  --search-icon: 40px;

  display: flex;
  height: 100%;
  background-color: transparent;
  transform: translateX(240px);
  transition-property: background-color, transform;
  transition: transform ${ANIMATION_DURATION}ms ease-out 0s;

  svg {
    margin-bottom: -6px;
  }

  button {
    margin-left: 0;
  }

  ${SearchInput} {
    transition: opacity ${ANIMATION_DURATION}ms linear 0s;
    opacity: 0;
  }

  ${({ $isFocused }) => $isFocused && css`
    background-color: ${({ theme }) => theme.colors.neutral};
    transform: translateX(0);

    ${SearchInput} {
      opacity: 1.0;
    }
  `};
`;

export const AutocompleteComboBox = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: block;
  width: 100%;
`;

export const AutocompleteResults = styled.ul`
  list-style: none;
  overflow: hidden;
  position: relative;
  margin: 0.5rem 0 0;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  border-radius: 8px;
`;

export const AutocompleteResult = styled.li`
  ${({ theme }) => theme.typography.body};
  cursor: pointer;
  margin-bottom: 0;
  padding: 0.5rem 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral};

  mark {
    background-color: ${({ theme }) => theme.colors.accentOne};
  }

  &:last-child {
    border-bottom: none;
  }

  &[aria-selected="true"] {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.accentTwo};
  }
`;
