import React, { useEffect, useMemo, useState } from "react";

import { IS_CLIENT } from "~/constants";
import { searchClient } from "~/config/search";
import { Main } from "~/layouts";
import { HeroSearch } from "~/components/hero";
import { Footer } from "~/components/footer";

import {
  SearchCollections,
  SearchPagination,
  SearchBox,
} from "~/components/search";

import {
  InstantSearch,
  // SearchBox,
  // Pagination,
  // Highlight,
  // ClearRefinements,
  // RefinementList,
  Configure,
} from "react-instantsearch-dom";

const SearchPage = ({ location }) => {
  const baseUrl = useMemo(() => {
    return new URL(
      `${location.origin || process.env.GATSBY_SITE_URL}${location.pathname}`,
    );
  }, [location.origin, location.pathname]);

  const params = new URLSearchParams(location.search);
  const [query, setQuery] = useState(params.get("q"));
  const [page, setPage] = useState(params.get("page") || "1");
  const onSearchStateChange = (searchState) => {
    setQuery(searchState.query);
    setPage(searchState.page);
  };

  useEffect(() => {
    if (IS_CLIENT) {
      const method = query ? "set" : "delete";
      baseUrl.searchParams[method]("q", query || "");
      window.history.replaceState({}, '', baseUrl.toString());
    }
  }, [baseUrl, query]);

  useEffect(() => {
    if (IS_CLIENT) {
      const method = page ? "set" : "delete";
      baseUrl.searchParams[method]("page", page || "");
      window.history.replaceState({}, '', baseUrl.toString());
    }
  }, [baseUrl, page]);

  return (
    <Main>
      <InstantSearch
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        searchClient={searchClient}
        searchState={{ query, page }}
        onSearchStateChange={onSearchStateChange}
      >
        <HeroSearch searchTerm={query || "All Cards"} />

        {/*<div className="left-panel">
          <ClearRefinements />
          <h2>Designers</h2>
          <RefinementList attribute="vendor" />
          <Configure hitsPerPage={12} />
        </div>*/}

        <Configure hitsPerPage={12} />
        <SearchBox />
        <SearchCollections />
        <SearchPagination />

        {/*<Pagination />*/}

        <Footer />
      </InstantSearch>
    </Main>
  );
}

export default SearchPage
