import React, { useMemo, useState, useRef } from "react";
import { useDebounce } from "react-use";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { createAutocomplete } from '@algolia/autocomplete-core';
import algoliasearch from "algoliasearch/lite";
import { Link } from "gatsby";

import { SearchIcon } from "~/components/icons";
import { NavigationAction } from "~/components/navigation";
import { ANIMATION_DURATION } from "./search.constants";

import {
  SearchForm,
  SearchInputContainer,
  SearchInput,
  SearchAnimation,
  AutocompleteComboBox,
  AutocompleteResults,
  AutocompleteResult,
} from "./search.style";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_PUBLIC_API_KEY,
);

export const Search = () => {
  const inputRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const [autocompleteState, setAutocompleteState] = useState({});
  const onFocus = (evt) => {
    evt.preventDefault();
    setIsFocused(true);
  }

  useDebounce(() => {
    inputRef.current[isFocused ? "focus" : "blur"]();
  }, ANIMATION_DURATION, [isFocused]);

  const autocomplete = useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // setIsFocused(state.isOpen || state.query);
          setAutocompleteState(state);
        },
        autoFocus: false,
        debug: true,
        getSources() {
          return [
            {
              sourceId: "products",
              getItemInputValue({ item }) {
                return item.query;
              },
              onActive(props) {
                console.log('props ', props);
              },
              getItems({ query }) {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: process.env.GATSBY_ALGOLIA_INDEX_NAME,
                      query,
                      params: {
                        hitsPerPage: 6,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                });
              },
              getItemUrl({ item }) {
                return `/cards/${item.slug}`;
              },
            },
          ];
        },
      }),
    []
  );

  return (
    <SearchForm
      {...autocomplete.getFormProps({
        inputElement: inputRef.current
      })}
    >
      <SearchAnimation $isFocused={isFocused}>
        <SearchInputContainer $isFocused={isFocused}>
          <NavigationAction onClick={onFocus}>
            <SearchIcon />
          </NavigationAction>
          <SearchInput
            ref={inputRef}
            {...autocomplete.getInputProps({})}
          />
        </SearchInputContainer>
      </SearchAnimation>

      <AutocompleteComboBox {...autocomplete.getRootProps({})}>
        <div {...autocomplete.getPanelProps({})}>
          {autocompleteState.isOpen &&
            autocompleteState.collections.map((collection, index) => {
              const { source, items } = collection;
              return (
                <div key={`source-${index}`} className="aa-Source">
                  {items.length > 0 && (
                    <AutocompleteResults
                      {...autocomplete.getListProps()}
                    >
                      {items.map((item) => (
                        <Link
                          key={item.objectID}
                          to={`/cards/${item.slug}`}
                        >
                          <AutocompleteResult
                            className="aa-Item"
                            {...autocomplete.getItemProps({ item, source })}
                            dangerouslySetInnerHTML={{
                              __html: item._highlightResult?.title?.value,
                            }}
                          />
                        </Link>
                      ))}
                    </AutocompleteResults>
                  )}
                </div>
              );
            })}
        </div>
      </AutocompleteComboBox>
    </SearchForm>
  );
};
