import React from "react";
import { connectPagination } from "react-instantsearch-dom";

import { smoothScroll } from "~/utils/scroll";
import { Pagination } from "~/components/pagination";

const CustomPagination = ({
  currentRefinement,
  nbPages,
  refine,
  createURL,
  ...props
}) => {
  const pageContext = {
    humanPageNumber: currentRefinement,
    numberOfPages: nbPages,
  };

  const onRefine = (event) => {
    event.preventDefault();
    refine(event.target.dataset.page);
    smoothScroll(0, 100);
  };

  return (
    <Pagination
      pathPrefix="/search"
      pageContext={pageContext}
      onRefine={onRefine}
    />
  );
}

export const SearchPagination = connectPagination(
  CustomPagination,
);
