import styled from "styled-components";

import { IS_SMALL } from "~/utils/media";

export const SearchBoxWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  width: auto;
  max-width: 480px;
  margin: -28px auto 0;
  padding-left: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 28px;
  box-shadow: ${({ theme }) => theme.shadows[1]};

  ${IS_SMALL} {
    margin-right: 3rem;
    margin-left: 3rem;
  }
`;

export const SearchBoxInput = styled.input`
  width: 100%;
  padding: 1rem;
  border: none;
  outline: none;
`;
