import { clamp, round, within } from "./math";

export const ease = {
  linear(t) {
    return t;
  },
  inQuad(t) {
    return t * t;
  },
  outQuad(t) {
    return t * (2 - t);
  },
  inOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },
  inCubic(t) {
    return t * t * t;
  },
  outCubic(t) {
    return --t * t * t + 1;
  },
  inOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  },
  inQuart(t) {
    return t * t * t * t;
  },
  outQuart(t) {
    return 1 - --t * t * t * t;
  },
  inOutQuart(t) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
  },
  inQuint(t) {
    return t * t * t * t * t;
  },
  outQuint(t) {
    return 1 + --t * t * t * t * t;
  },
  inOutQuint(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  },
};

/**
 * Interpolates between two given values for
 * a specified amount of time with optional easing
 *
 * @param startValue {Number} - Value to start with
 * @param endValue {Number} - Value to end with
 * @param ms {Number} - How long the interpolation should take in milliseconds. Defaults to 300.
 * @param easing {String} - Easing function name found in `~/utils/animations`.
 * @param callback {Function} - Function to trigger on each tick.
 */
export const interpolate = ({
  startValue = 0,
  endValue = 0,
  ms = 300,
  easing = "outQuad",
  callback = () => {},
}) => {
  const startTime = Date.now();
  const endTime = startTime + ms;
  const difference = endValue - startValue;
  let currentValue = startValue;

  const tick = () => {
    let now = Date.now();
    let delta = (now - startTime) / ms;
    let elapsed = round(clamp(ease[easing](delta), 0, 1));

    currentValue = startValue + difference * elapsed;

    if (elapsed < 1 && !within(currentValue, endValue, 0.1) && now < endTime) {
      return requestAnimationFrame(() => {
        callback(elapsed);
        tick();
      });
    }

    callback(elapsed, true);
  };

  tick();
};
