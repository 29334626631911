import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";

import { SearchIcon } from "~/components/icons";
import {
  SearchBoxWrapper,
  SearchBoxInput,
} from "./search-box.style";

const CustomSearchBox = ({
  currentRefinement,
  isSearchStalled,
  refine,
}) => {
  const onChange = ({ currentTarget }) =>
    refine(currentTarget.value);

  return (
    <SearchBoxWrapper>
      <SearchIcon />
      <SearchBoxInput
        value={currentRefinement}
        placeholder="Search Cards"
        onChange={onChange}
      />
    </SearchBoxWrapper>
  );
}

export const SearchBox = connectSearchBox(CustomSearchBox);
