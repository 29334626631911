import React from "react";
import PropTypes from "prop-types";

import { Grid } from "~/components/grid";
import { ProductItem } from "~/components/products/product-item";

import {
  CollectionWrapper,
  CollectionItem,
} from "./collections.style";

export const Collections = ({ products, limit }) => {
  return (
    <Grid>
      <CollectionWrapper>
        {products.map((product) => (
          <CollectionItem key={product.id}>
            <ProductItem {...product} />
          </CollectionItem>
        ))}
      </CollectionWrapper>
    </Grid>
  )
};
Collections.defaultProps = {
  products: [],
  limit: 12,
};
Collections.propTypes = {
  products: PropTypes.array,
  limit: PropTypes.number,
};
