import styled from "styled-components";

export const PaginationWrapper = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  padding-bottom: 4rem;
`;

export const PaginationItem = styled.li`
  margin: 0 0.25rem;

  a,
  button {
    padding: 0.25rem;
  }
`;
