import React from "react";
import PropTypes from "prop-types";
import { connectHits } from "react-instantsearch-dom";
import { Collections } from "~/components/collections";

const Hits = ({ hits }) => {
  const products = hits.map(({ featuredImage, ...props }) => ({
    ...props,
    id: props.objectID,
    images: [{
      src: featuredImage?.transformedSrc,
    }],
  }));

  return (
    <Collections products={products} />
  );
};
Hits.propTypes = {
  hits: PropTypes.array,
};

export const SearchCollections = connectHits(Hits);
