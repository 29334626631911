import { IS_CLIENT } from "~/constants";
import { ease } from "./animations";
import { clamp, round, within } from "./math";

/**
 * Smooth window scrolling with time and easing support.
 *
 * @param endPosition {Number} - Where in the document the page should scroll to
 * @param ms {Number} - How long the scroll should take in milliseconds. Defaults to 300.
 * @param easing {String} - Easing function name found in `~/utils/animations`.
 */
export const smoothScroll = (
  endPosition = 0,
  ms = 300,
  easing = "outCubic",
) => {
  const startTime = Date.now();
  const endTime = startTime + ms;
  const startPosition = IS_CLIENT ? window.scrollY : 0;
  const distance = endPosition - startPosition;
  let currentY = 0;

  const step = () => {
    let now = Date.now();
    let delta = (now - startTime) / ms;
    let elapsed = round(clamp(ease[easing](delta), 0, 1));

    elapsed = elapsed > 1 ? 1 : elapsed;
    currentY = startPosition + distance * elapsed;

    window.scrollTo(0, currentY);

    if ((elapsed < 1 || !within(currentY, endPosition, 0.1)) && now < endTime) {
      requestAnimationFrame(step);
    }
  };

  step();
};
