import React from "react";
import PropTypes from "prop-types";
import range from "lodash/range";

import { clamp } from "~/utils/math";
import { Button, BUTTON_VARIANTS } from "~/components/button";
import { ChevronIcon, SkipArrowIcon } from "~/components/icons";

import {
  PaginationWrapper,
  PaginationItem,
} from "./pagination.style";

export const Pagination = ({
  pathPrefix,
  pageContext,
  maxDisplay,
  onRefine,
}) => {
  const {
    humanPageNumber,
    numberOfPages,
    nextPagePath,
    previousPagePath,
  } = pageContext;

  const firstPageNumber = 1;
  const lastPageNumber = numberOfPages;
  const startPageNumber = clamp(
    Math.min(humanPageNumber, numberOfPages - maxDisplay + 1),
    firstPageNumber,
    numberOfPages,
  );
  const endPageNumber = Math.min(humanPageNumber + maxDisplay, numberOfPages + 1);
  const prevPageNumber = Math.max(1, startPageNumber - 1);
  const nextPageNumber = Math.min(numberOfPages, humanPageNumber + 1);

  return (
    <PaginationWrapper>
      <PaginationItem>
        <Button
          to={pathPrefix}
          data-page={1}
          disabled={(humanPageNumber === firstPageNumber)}
          icon={<SkipArrowIcon rotate={180} />}
          variant={BUTTON_VARIANTS.SIMPLE}
          onClick={onRefine}
        />
      </PaginationItem>
      <PaginationItem>
        <Button
          to={previousPagePath || `${pathPrefix}/${prevPageNumber}`}
          data-page={prevPageNumber}
          disabled={(!previousPagePath && humanPageNumber <= prevPageNumber)}
          icon={<ChevronIcon rotate={180} />}
          variant={BUTTON_VARIANTS.SIMPLE}
          onClick={onRefine}
        />
      </PaginationItem>
      {range(startPageNumber, endPageNumber).map(page => (
        <PaginationItem key={page}>
          <Button
            to={`${pathPrefix}/${page}`}
            label={page}
            data-page={page}
            disabled={!!(humanPageNumber === page)}
            variant={BUTTON_VARIANTS.SIMPLE}
            onClick={onRefine}
          />
        </PaginationItem>
      ))}
      <PaginationItem>
        <Button
          to={nextPagePath || `${pathPrefix}/${nextPageNumber}`}
          data-page={nextPageNumber}
          disabled={(!nextPagePath && humanPageNumber >= nextPageNumber)}
          icon={<ChevronIcon />}
          variant={BUTTON_VARIANTS.SIMPLE}
          onClick={onRefine}
        />
      </PaginationItem>
      <PaginationItem>
        <Button
          to={`${pathPrefix}/${numberOfPages}`}
          data-page={numberOfPages}
          disabled={(humanPageNumber === lastPageNumber)}
          icon={<SkipArrowIcon />}
          variant={BUTTON_VARIANTS.SIMPLE}
          onClick={onRefine}
        />
      </PaginationItem>
    </PaginationWrapper>
  );
};
Pagination.defaultProps = {
  pathPrefix: "",
  maxDisplay: 5,
  onRefine: () => {}, // Option to programmatically change pages
};
Pagination.propTypes = {
  pageContext: PropTypes.object,
  maxDisplay: PropTypes.number,
};
