import styled from "styled-components";

import { IS_MEDIUM } from "~/utils/media";

export const CollectionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 -0.5rem 4rem;
  padding-top: 4rem;

  ul {
    list-style: none;
  }

  ${IS_MEDIUM} {
    max-width: 960px;
  }
`;

export const CollectionItem = styled.div`
  width: 33.33%;
  padding: 0 0.5rem 1rem;

  ${IS_MEDIUM} {
    width: 50%;
  }
`;
